<template>
    <div class="mt-32 mb-12">
        <div class="clock-display w-full text-center font-bold text-center">
            <a v-text="clockDisplay"
               class="text-lg md:text-4xl lg:text-6xl cursor-pointer"
               title="Klicke hier um die Anzahl an Minuten einzustellen"
               v-on:click="openSettings()"></a>
            <a class="inline-block ml-2 md:ml-4 lg:ml-12 text-sm md:text-3xl lg:text-5xl leading-none cursor-pointer relative"
               v-on:click="pause = !pause">
                <template v-if="pause">
                    <font-awesome-icon :icon="['fas', 'play']"/>
                </template>
                <template v-else>
                    <font-awesome-icon :icon="['fas', 'pause']"/>
                </template>
            </a>
        </div>

        <div class="clock-outer mx-auto border border-gray-footer w-1/2">
            <div class="clock-inner w-full h-full p-8 relative">
                <canvas class="w-full h-full"></canvas>
                <a class="logo"
                   href="https://personalentwicklung.plus" target="_blank">
                    <img :src="logo" alt="personalentwicklung.plus Logo">
                </a>
            </div>
        </div>

        <div class="text-center text-gray-footer mt-2 leading-none uppercase font-bold text-sm">
            <a href="https://personalentwicklung.plus/impressum/">Impressum</a>
            <span class="hidden md:inline">
                |
            </span>
            <span class="block md:hidden">
                <br>
            </span>
            <a href="https://personalentwicklung.plus/datenschutzerklaerung/">Datenschutzerklärung</a>
        </div>

        <div class="modal fixed z-10 inset-0 overflow-y-auto" v-show="settingsOpen">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div class="fixed inset-0 transition-opacity">
                    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;

                <div
                    class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <p class="text-sm leading-5 text-gray-footer">
                            Stelle hier ein, wie viele Minuten der Timer herunterzählen soll. Du kannst die Anzahl der Minuten auch beim Aufruf des Timers festlegen. Wenn du zum Beispiel  <a href="http://timer.personalentwicklung.plus/#30">http://timer.personalentwicklung.plus/#30</a> aufrufst, wird der Timer auf 30 Minuten gestellt. Maximal kann der Timer 60 Minuten laufen.
                        </p>
                        <p class="mt-4">
                            <label>
                                <input type="number" min="1" max="60" name="minutes" v-model="formMinutes" class="rounded border border-gray-500 text-lg font-bold py-2 px-4" required>
                            </label>
                        </p>
                    </div>
                    <div class="bg-gray-100 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                          <button type="submit"
                                  class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-coral text-base leading-6 font-medium text-white shadow-sm hover:bg-coral-dark transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                                  v-on:click="setTimer">
                            Timer stellen
                          </button>
                        </span>
                        <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                          <button type="button"
                                  class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-500 shadow-sm hover:text-gray-footer transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                                  v-on:click="settingsOpen = false">
                            Abbrechen
                          </button>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <audio :src="alarm" preload="auto"></audio>
    </div>
</template>

<script>
import logo from './../images/logo.png'
import alarm from './../audio/alarm.wav'

export default {
    name: 'Timer',
    data () {
        return {
            ctx: null,
            seconds: 15 * 60,
            secondsMax: 15 * 60,
            formMinutes: 15 * 60,
            interval: 1000,
            expected: null,
            pause: true,
            repainted: true,
            alarm: alarm,
            logo: logo,
            settingsOpen: false
        }
    },
    mounted () {
        const that = this
        const canvas = this.$el.querySelector('canvas')
        this.ctx = canvas.getContext('2d')

        let hash = window.location.hash
        let minutes = parseInt(hash.replace('#', ''))
        if (isNaN(minutes)) {
            console.info('Could not convert hash to number: ', window.location.hash)
            minutes = 15
        }
        if (minutes > 60) {
            minutes = 60
        }

        this.secondsMax = minutes * 60
        this.seconds = this.secondsMax
        this.formMinutes = minutes

        window.addEventListener('resize', function () {
            that.ctx.canvas.width = canvas.clientWidth
            that.ctx.canvas.height = canvas.clientHeight

            if (that.repainted) {
                that.repainted = false
                window.requestAnimationFrame(function () {
                    that.drawClock()

                    that.repainted = true
                })
            }
        })

        this.drawClock()
    },
    methods: {
        drawClock () {
            console.debug('Draw clock')

            const canvas = this.$el.querySelector('canvas')
            const responsiveRatio = canvas.clientWidth / 100

            // Set canvas pixel size to canvas dimensions
            this.ctx.canvas.width = canvas.clientWidth
            this.ctx.canvas.height = canvas.clientHeight

            // Set origin to center of canvas
            this.ctx.translate(canvas.width * 0.5, canvas.height * 0.5)

            // Set drawing styles
            this.ctx.fillStyle = 'black'

            // Draw minute markers
            this.addMarker(this.ctx, responsiveRatio, 0)
            this.addMarker(this.ctx, responsiveRatio, 30)
            this.addMarker(this.ctx, responsiveRatio, 60)
            this.addMarker(this.ctx, responsiveRatio, 90)
            this.addMarker(this.ctx, responsiveRatio, 120)
            this.addMarker(this.ctx, responsiveRatio, 150)
            this.addMarker(this.ctx, responsiveRatio, 180)
            this.addMarker(this.ctx, responsiveRatio, 210)
            this.addMarker(this.ctx, responsiveRatio, 240)
            this.addMarker(this.ctx, responsiveRatio, 270)
            this.addMarker(this.ctx, responsiveRatio, 300)
            this.addMarker(this.ctx, responsiveRatio, 330)

            // Draw timeout circle
            this.ctx.save()
            this.ctx.beginPath()
            this.ctx.fillStyle = '#ff635e'
            const degreeFinished = 360 - ((this.seconds / this.secondsMax) * 360) - 90
            this.ctx.arc(0, 0, responsiveRatio * 48, 270 * Math.PI / 180, degreeFinished * Math.PI / 180, true)
            this.ctx.lineTo(0, 0)
            this.ctx.fill()
            this.ctx.closePath()
            this.ctx.restore()

            // Draw center circle
            this.ctx.save()
            this.ctx.beginPath()
            this.ctx.arc(0, 0, responsiveRatio * 10, 0, 360 * Math.PI / 180)
            this.ctx.fillStyle = '#19255d'
            this.ctx.fill()
            this.ctx.closePath()
            this.ctx.restore()
        },

        /**
         *
         * @param {CanvasRenderingContext2D} ctx
         * @param {Number} responsiveRatio
         * @param {Number} angle
         */
        addMarker (ctx, responsiveRatio, angle) {
            ctx.save()

            // Convert angle from deg to rad
            angle *= Math.PI / 180

            ctx.rotate(angle)
            ctx.fillRect(responsiveRatio * 35, 0, responsiveRatio * 12, responsiveRatio)

            ctx.restore()
        },

        step () {
            if (this.pause === true) {
                console.debug('Abort timer interval.')
                return
            }

            if (this.seconds - 1 <= 0) {
                this.seconds--
                this.timerEnd()
                return
            }

            this.seconds--

            let dt = Date.now() - this.expected
            if (dt > this.interval) {
                console.error('Step too large')
            }

            this.expected += this.interval
            setTimeout(this.step, Math.max(0, this.interval - dt))
        },

        timerEnd () {
            console.debug('Timer ended.')

            this.$el.querySelector('audio').play()
            this.pause = true
        },

        openSettings () {
            this.pause = true
            this.settingsOpen = true
        },

        setTimer () {
            this.secondsMax = this.formMinutes * 60
            this.seconds = this.formMinutes * 60

            this.settingsOpen = false
        }
    },
    computed: {
        clockDisplay () {
            const minutes = Math.floor(this.seconds / 60) % 60
            const seconds = this.seconds % 60

            return [minutes, seconds]
                .map(v => v < 10 ? '0' + v : v)
                .join(":")
        }
    },
    watch: {
        pause () {
            if (this.pause === true) {
                console.debug('Timer is now in pause mode.')

                return
            }

            this.expected = Date.now() + this.interval
            setTimeout(this.step, this.interval)
        },

        seconds () {
            if (this.seconds < 0) {
                this.seconds = 0
            }

            const that = this

            window.requestAnimationFrame(function () {
                that.drawClock()
            })
        }
    }
}
</script>

<style scoped>
.clock-outer {
    width: 45vw;
    height: 45vw;
    max-height: 100vh;
    border-width: 1rem;
    border-radius: 2rem;
}

a {
    @apply transition-colors duration-200;
}

a:hover {
    @apply text-pen;
}

.logo {
    @apply transform absolute right-0 bottom-0 w-1/2 pb-1 -mb-4;
    transform: rotate(-90deg) translate(100%, -100%);
    transform-origin: 100% 0;
    right: 0;
}
</style>
