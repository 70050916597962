<template>
    <div id="app" class="flex flex-col justify-center h-screen">
        <Timer></Timer>
    </div>
</template>

<script>
import Timer from './components/Timer.vue'

export default {
    name: 'App',
    components: {
        Timer
    }
}
</script>

<style scoped>
</style>
